import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    city: '',
    email: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    // Send a POST request to the backend to create a new customer
    await axios.post(`${process.env.REACT_APP_API_URL}/api/customers`, formData);

    // Automatically log the user in after successful sign-up
    const endpoint = `${process.env.REACT_APP_API_URL}/api/customers/login`;

    // Log in the user using the same details they just signed up with
    const response = await axios.post(endpoint, {
      email: formData.email,
      password: formData.password,
    });

    // Extract data from the response
    const { token, userId, role } = response.data;

    // Save the user details to localStorage
    localStorage.setItem('authToken', token);
    localStorage.setItem('userId', userId);  // Store the userId
    localStorage.setItem('userRole', role);  // Store the user role (should be 'customer')

    // Redirect to the /request page after successful login
    navigate('/request');
    window.location.reload();
  } catch (error) {
    alert('Sign-up failed. Please try again.');
    console.error('Sign-up or login failed:', {
      message: error.message,
      code: error.code,
      response: error.response ? error.response.data : null,
      config: error.config,
    });
  }
};  return (
    <Container>
      <FormWrapper>
        <Title>Sign Up</Title>
        <InfoBox>
          Are you a tutor? <a href="/signuptutor">Sign up here</a>.
        </InfoBox>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="city">City</Label>
            <Input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <Button type="submit">Sign Up</Button>
        </Form>
      </FormWrapper>
    </Container>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  font-family: 'DM Serif Display', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #a19d91;
  text-align: center;
  position: relative;
  overflow-x: hidden;
  padding: 70px 20px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const FormWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: calc(1.5rem + 1vw);
  color: #333333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: #555555;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  font-size: calc(1rem + 0.5vw);
  color: #ffffff;
  background-color: #1b2625;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #166fe5;
  }
`;

const InfoBox = styled.div`
  margin-top: 1rem;
  text-align: center;
  color: #555555;

  a {
    color: #1877f2;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default SignUp;
