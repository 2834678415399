import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import SignUpTutor from './pages/SignUpTutor';  // Import the SignUpTutor component
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Schedule from './pages/Schedule';
import Request from './pages/Request';  // Import the Request component
import ViewSessions from './pages/view-sessions'; // Import the ViewSessions component
import Contact from './pages/Contact';  // Import the Contact component
import 'bootstrap/dist/css/bootstrap.min.css';
import PayPalConfirmation from './pages/paypal-confirmation'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signuptutor" element={<SignUpTutor />} />  {/* Add the SignUpTutor route */}
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/request" element={<Request />} />  {/* Add the Request route */}
          <Route path="/view-sessions" element={<ViewSessions />} />  {/* Add the ViewSessions route */}
          <Route path="/contact" element={<Contact />} />  {/* Add the Contact route */}
      	  <Route path="/paypal-success" element={<PayPalConfirmation />} />  {/* Change to paypal-success without param */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
