import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  // Prepare the form data to be sent
  const formDataToSend = new FormData();
  formDataToSend.append('name', formData.name);
  formDataToSend.append('email', formData.email);
  formDataToSend.append('message', formData.message);
  if (file) {
    formDataToSend.append('file', file);
  }

  try {
    // Adjust the URL if your backend runs on a different port or URL
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formDataToSend, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Handle success
    alert('Your message has been sent successfully!');
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setFile(null);
  } catch (error) {
    // Handle errors
    console.error('There was an error sending your message:', error);
    alert('There was an error sending your message. Please try again.');
  }
};


  return (
    <div style={{ fontFamily: 'DM Serif Display, serif', backgroundColor: '#a19d91', textAlign: 'center', position: 'relative', overflowX: 'hidden', minHeight: '100vh', padding: '50px 20px' }}>
      <h2 style={{ fontSize: 'calc(2vw + 2vh)', marginBottom: '20px', fontWeight: 'bold', letterSpacing: '1px', color: '#333' }}>Get In Touch</h2>
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }}>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Name:</label>
          <input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} 
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Email:</label>
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} 
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Message:</label>
          <textarea 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            required 
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', minHeight: '100px' }} 
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Attach File:</label>
          <input 
            type="file" 
            onChange={handleFileChange} 
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }} 
          />
        </div>
        <button type="submit" style={{ width: '100%', padding: '10px', fontSize: 'calc(1rem + 0.5vw)', color: '#ffffff', backgroundColor: '#1b2625', border: 'none', borderRadius: '8px', cursor: 'pointer', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)' }}>
          Send Message
        </button>
      </form>
    </div>
  );
}

export default Contact;
