import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Banner = styled.div`
  background-color: #ffffff; /* Elegant soft red color */
  color: black;
  padding: 0.5rem 0; /* Thinner padding */
  text-align: center;
  font-size: 1rem; /* Smaller font size */
  font-weight: 300; /* Thinner font weight */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow for subtle effect */

  @media (max-width: 768px) {
    font-size: 0.875rem; /* Adjusted font size for mobile */
    padding: 0.4rem 0; /* Thinner padding for mobile */
  }
`;

const NavbarContainer = styled.nav`
  background-color: rgba(202, 115, 75, 0);
  width: 100%;
  margin: 0 auto;
  padding: 1.9rem 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: absolute;
  top: 40px; /* Adjusted to account for thinner banner */
  left: 0;
  right: 0;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 1rem;
    top: 40px;
  }
`;

const NavBrand = styled(Link)`
  color: rgba(27, 38, 37, 1);
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const NavLinks = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const NavItem = styled.li`
  margin-left: 0;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgba(27, 38, 37, 1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #000;
    color: #fff;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.4rem 0.8rem;
  }
`;

const LogoutButton = styled.button`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgba(27, 38, 37, 1);
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #000;
    color: #fff;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.4rem 0.8rem;
  }
`;

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    setIsLoggedIn(!!token);  // Set isLoggedIn based on the presence of the token
  }, []);  // Only run once on component mount

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);

    if (window.location.pathname === '/') {
      window.location.reload();
    } else {
      navigate('/');
    }
  };

  return (
    <>
      {/* Banner above the navbar */}
      <Banner>
        FIRST SESSION FREE - Sign Up in 30 Seconds!
      </Banner>

      <NavbarContainer>
        <NavBrand to="/">Tutor</NavBrand>
        <NavLinks>
          {!isLoggedIn ? (
            <>
              <NavItem>
                <NavLink to="/signup">Sign Up</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/login">Login</NavLink>
              </NavItem>
            </>
          ) : (
            <NavItem>
              <LogoutButton onClick={handleLogout}>Log Out</LogoutButton>
            </NavItem>
          )}
        </NavLinks>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
