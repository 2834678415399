import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: 'rgba(27, 38, 37, 1)', color: '#fff' }} className="footer mt-auto py-3">
      <div className="container">
        <span>Tutoring &copy; 2024</span>
      </div>
    </footer>
  );
}

export default Footer;
