import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const ViewSessions = () => {
  const [sessions, setSessions] = useState([]);
  const [acceptedSessions, setAcceptedSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isApproved, setIsApproved] = useState(null); // Initialize as null to check approval status later

  useEffect(() => {
  const fetchTutorApproval = async () => {
    try {
      const tutorId = localStorage.getItem('userId');
      const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage
      if (!tutorId || !token) {
        throw new Error('No tutor ID or token found in localStorage');
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/tutors/${tutorId}`, {
        headers: {
          Authorization: `Bearer ${token}` // Include the token in the Authorization header
        }
      });

      setIsApproved(response.data.employeeApproved);
      if (response.data.employeeApproved) {
        await fetchSessions();
        await fetchAcceptedSessions();
      }
    } catch (err) {
      console.error('Error fetching tutor approval status:', err);
      setError('Error checking tutor approval status');
    } finally {
      setLoading(false);
    }
  };
    const fetchSessions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/requests/sessions`);
        setSessions(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchAcceptedSessions = async () => {
      try {
        const tutorId = localStorage.getItem('userId');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/requests/accepted-sessions/${tutorId}`);
        setAcceptedSessions(response.data);
      } catch (err) {
        console.error('Error fetching accepted sessions:', err);
      }
    };

    fetchTutorApproval();
  }, []);

  const handleAcceptSession = async (sessionId) => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('You need to log in before accepting a session.');
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/requests/${sessionId}/match-tutor`, {
        userId: userId,
      });

      setSessions((prevSessions) => prevSessions.filter(session => session._id !== sessionId));
      setAcceptedSessions((prevAcceptedSessions) => [
        ...prevAcceptedSessions,
        response.data,
      ]);

      await axios.post(`${process.env.REACT_APP_API_URL}/api/requests/${sessionId}/send-email`);
      window.location.reload();
    } catch (error) {
      console.error('Error accepting session:', error.response?.data || error.message);
    }
  };

  if (loading) {
    return (
      <Container>
        <Message>Loading sessions...</Message>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Message error>{`Error fetching sessions: ${error}`}</Message>
      </Container>
    );
  }

  if (isApproved === false) {
    return (
      <Container>
        <Message>Please wait for approval. Check your email for the next steps.</Message>
      </Container>
    );
  }

  if (isApproved === null) {
    return (
      <Container>
        <Message>Checking approval status...</Message>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Available Tutor Sessions</Title>
      <ScrollableTableContainer>
        <SessionTable>
          <thead>
            <tr>
              <TableHeader>Subject</TableHeader>
              <TableHeader>Date</TableHeader>
              <TableHeader>Time</TableHeader>
              <TableHeader>Location</TableHeader>
              <TableHeader>Customer</TableHeader>
              <TableHeader>Education Level</TableHeader>
              <TableHeader>Action</TableHeader>
            </tr>
          </thead>
          <tbody>
            {sessions.map(session => (
              <TableRow key={session._id}>
                <TableCell>{session.subject}</TableCell>
                <TableCell>{new Date(session.date).toLocaleDateString()}</TableCell>
                <TableCell>{`${session.startTime} - ${session.endTime}`}</TableCell>
                <TableCell>{session.location}</TableCell>
                <TableCell>{session.customer ? session.customer.name : 'N/A'}</TableCell>
                <TableCell>{session.educationLevel}</TableCell>
                <TableCell>
                  <AcceptButton onClick={() => handleAcceptSession(session._id)}>Accept</AcceptButton>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </SessionTable>
      </ScrollableTableContainer>

      <AcceptedSessionsTitle>Accepted Sessions</AcceptedSessionsTitle>
      {acceptedSessions.length > 0 ? (
        <ScrollableTableContainer>
          <SessionTable>
            <thead>
              <tr>
                <TableHeader>Subject</TableHeader>
                <TableHeader>Date</TableHeader>
                <TableHeader>Time</TableHeader>
                <TableHeader>Location</TableHeader>
                <TableHeader>Customer</TableHeader>
                <TableHeader>Customer Email</TableHeader>
                <TableHeader>Education Level</TableHeader>
              </tr>
            </thead>
            <tbody>
              {acceptedSessions.map(session => (
                <TableRow key={session._id}>
                  <TableCell>{session.subject}</TableCell>
                  <TableCell>{new Date(session.date).toLocaleDateString()}</TableCell>
                  <TableCell>{`${session.startTime} - ${session.endTime}`}</TableCell>
                  <TableCell>{session.location}</TableCell>
                  <TableCell>{session.customer ? session.customer.name : 'N/A'}</TableCell>
                  <TableCell>{session.customer ? session.customer.email : 'N/A'}</TableCell>
                  <TableCell>{session.educationLevel}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </SessionTable>
        </ScrollableTableContainer>
      ) : (
        <Message>No accepted sessions yet.</Message>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: 'DM Serif Display', serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #a19d91;
  text-align: center;
  padding: 70px 20px;
  width: 100%; /* Full width for centering content */
`;

const ScrollableTableContainer = styled.div`
  width: 90%; /* Span 90% of the page width for the tables */
  overflow-x: auto; /* Make tables horizontally scrollable */
  margin-bottom: 20px;
  margin: 0 auto; /* Center the table container */
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: calc(1.5rem + 1vw);
  color: #333;
`;

const AcceptedSessionsTitle = styled.h3`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: calc(1.2rem + 1vw);
  color: #333;
`;

const Message = styled.p`
  font-size: 1.2rem;
  color: ${(props) => (props.error ? 'red' : '#333')};
`;

const SessionTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  font-size: 1rem;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 1rem;
  text-align: left;
`;

const AcceptButton = styled.button`
  padding: 8px 16px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #1b2625;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #166fe5;
  }
`;

export default ViewSessions;
