import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PayPalConfirmation = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const handlePaymentSuccess = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const requestId = searchParams.get('requestId');
            const token = searchParams.get('token');
            const payerId = searchParams.get('PayerID');

        console.log('Request ID:', requestId); // Check if requestId is being captured
        console.log('Token:', token); // Check if token is being captured
        console.log('PayerID:', payerId); // Check if PayerID is being captured

            try {
const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/requests/paypal-success`, {
    params: { token, requestId, payerId }
});

            console.log('Payment response:', response); // Log the response to ensure the API call is successful


                if (response.status === 200) {
                    setMessage('Payment successful! Your session has been confirmed.');
                } else {
                    setError('Payment failed. Please try again.');
                }
            } catch (err) {
                setError('An error occurred during the payment process. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        handlePaymentSuccess();
    }, []);

    return (
        <div style={styles.container}>
            {loading ? (
                <div style={styles.loadingContainer}>
                    <p>Processing your payment, please wait...</p>
                </div>
            ) : error ? (
                <div style={styles.errorContainer}>
                    <p>{error}</p>
                    <button style={styles.button} onClick={() => navigate('/')}>Go back to homepage</button>
                </div>
            ) : (
                <div style={styles.successContainer}>
                    <h2>{message}</h2>
                    <p>Thank you for your payment. Your session is now confirmed.</p>
                    <button style={styles.button} onClick={() => navigate('/')}>Go to Dashboard</button>
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#f4f4f4',
        minHeight: '100vh',
    },
    loadingContainer: {
        margin: 'auto',
        padding: '20px',
    },
    errorContainer: {
        color: 'red',
        padding: '20px',
    },
    successContainer: {
        color: 'black',
        padding: '20px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#1b2625',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '15px',
    },
};

export default PayPalConfirmation;
