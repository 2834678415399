import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    userType: 'customer',  // Default to customer
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log("Submit started");

    try {
      const endpoint = formData.userType === 'tutor' 
        ? `${process.env.REACT_APP_API_URL}/api/tutors/login` 
        : `${process.env.REACT_APP_API_URL}/api/customers/login`;
      
    console.log('Making a request to:', endpoint);
    console.log('Request payload:', {
      email: formData.email,
      password: formData.password,
    });
	

    const response = await axios.post(endpoint, {
      email: formData.email,
      password: formData.password,
    });

    console.log('Response data:', response.data);

      const { token, userId, role } = response.data;

      // Save the user details to localStorage
      localStorage.setItem('authToken', token);
      localStorage.setItem('userId', userId); // Store the userId
      localStorage.setItem('userRole', role); // Store the user role (customer or tutor)

      // Log the role and userId after saving to localStorage
      console.log("Logged in user role:", localStorage.getItem('userRole'));
      console.log("Logged in user ID:", localStorage.getItem('userId'));

      // Redirect to homepage
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Login failed:', {
        message: error.message,
        code: error.code,
        response: error.response ? error.response.data : null,
        config: error.config,
      });
    }
  };

  return (
    <div style={{
      fontFamily: 'DM Serif Display, serif',
      backgroundColor: '#a19d91',
      textAlign: 'center',
      position: 'relative',
      overflowX: 'hidden',
      minHeight: '100vh',
      padding: '70px 20px',
    }}>
      <div style={{
        maxWidth: '600px',
        margin: '0 auto',
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      }}>
        <h2 style={{
          marginBottom: '20px',
          fontSize: 'calc(1.5rem + 1vw)',
          color: '#333333',
        }}>Login</h2>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>User Type:</label>
            <select 
              name="userType" 
              value={formData.userType} 
              onChange={handleChange} 
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            >
              <option value="customer">Customer</option>
              <option value="tutor">Tutor</option>
            </select>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              required
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              required
            />
          </div>
          <button type="submit" style={{
            width: '100%',
            padding: '10px',
            fontSize: 'calc(1rem + 0.5vw)',
            color: '#ffffff',
            backgroundColor: '#1b2625',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
          }}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
