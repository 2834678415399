import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

const TutorSignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    city: '',
    sex: '',
    profilePicture: null,
    bio: '',
    languages: [{ language: '', level: '' }],
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleLanguageChange = (index, e) => {
    const { name, value } = e.target;
    const newLanguages = [...formData.languages];
    newLanguages[index][name] = value;
    setFormData({
      ...formData,
      languages: newLanguages,
    });
  };

  const addLanguage = () => {
    setFormData({
      ...formData,
      languages: [...formData.languages, { language: '', level: '' }],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);
    formDataToSend.append('city', formData.city);
    formDataToSend.append('sex', formData.sex);
    formDataToSend.append('bio', formData.bio);
    formDataToSend.append('profilePicture', formData.profilePicture);

    // Convert languages array to JSON string
    formDataToSend.append('languages', JSON.stringify(formData.languages));

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/tutors/signup`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        alert('Sign up successful! You can now log in.');
		window.location.href = '/login'; // Redirect to the home directory
        // Optionally, redirect to the login page or any other page
      }
    } catch (error) {
      console.error('Error during sign up:', error.response?.data || error.message);
      alert('Sign up failed. Please try again.');
    }
  };

  return (
    <Container>
      <FormWrapper>
        <Title>Tutor Sign Up</Title>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Profile Picture</Label>
            <ProfilePicWrapper>
              <ProfilePic src={formData.profilePicture ? URL.createObjectURL(formData.profilePicture) : 'default-profile.png'} alt="Profile" />
              <Input
                type="file"
                id="profilePicture"
                name="profilePicture"
                accept="image/*"
                onChange={handleChange}
                style={{ display: 'none' }}
              />
              <UploadButton htmlFor="profilePicture">Upload</UploadButton>
            </ProfilePicWrapper>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="city">City</Label>
            <Input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="sex">Sex</Label>
            <Select
              id="sex"
              name="sex"
              value={formData.sex}
              onChange={handleChange}
              required
            >
              <option value="">Select...</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label>Language Levels</Label>
            <LanguageTable>
              <thead>
                <tr>
                  <th>Language</th>
                  <th>Level</th>
                </tr>
              </thead>
              <tbody>
                {formData.languages.map((language, index) => (
                  <tr key={index}>
                    <td>
                      <Input
                        type="text"
                        name="language"
                        value={language.language}
                        onChange={(e) => handleLanguageChange(index, e)}
                        required
                      />
                    </td>
                    <td>
                      <Select
                        name="level"
                        value={language.level}
                        onChange={(e) => handleLanguageChange(index, e)}
                        required
                      >
                        <option value="">Select...</option>
                        <option value="Beginner">Beginner</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Advanced">Advanced</option>
                        <option value="Fluent">Fluent</option>
                      </Select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </LanguageTable>
            <AddLanguageButton type="button" onClick={addLanguage}>Add Language</AddLanguageButton>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="bio">Bio</Label>
            <TextArea
              id="bio"
              name="bio"
              value={formData.bio}
              onChange={handleChange}
              rows="4"
              placeholder="Tell us about yourself"
              required
            />
          </FormGroup>
          <Button type="submit">Sign Up</Button>
        </Form>
      </FormWrapper>
    </Container>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  font-family: 'DM Serif Display', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #a19d91;
  text-align: center;
  position: relative;
  overflow-x: hidden;
  padding: 70px 20px;
  animation: ${fadeIn} 1s ease-in-out;
`;

const FormWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: calc(1.5rem + 1vw);
  color: #333333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  text-align: left;
  margin-bottom: 5px;
  color: #555555;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
  background-color: white;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  font-size: calc(1rem + 0.5vw);
  color: #ffffff;
  background-color: #1b2625;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #166fe5;
  }
`;

const ProfilePicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const ProfilePic = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
`;

const UploadButton = styled.label`
  background-color: #1b2625;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #166fe5;
  }
`;

const LanguageTable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const AddLanguageButton = styled.button`
  background-color: #1b2625;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #166fe5;
  }
`;

export default TutorSignUp;
